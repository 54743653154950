<template>

    <div class="game">

        <div ref="replay_control_center" class=" replay-control-center" >
        
        <div v-if="game_mode" class="d-flex flex-row">
            <div class="d-flex" v-if="this.SPYChart.prices && this.SPYChart.prices.length">
                <v-btn-toggle borderless class="py-0 mr-2 play-speed" mandatory color="primary" background-color="white"  v-model="play_speed">
                    <v-btn plain  elevation="0" large class="py-0" value="slow">Slow</v-btn>
                    <v-btn plain elevation="0" large class="py-0" value="fast">Fast</v-btn>
                </v-btn-toggle>
            </div>

            <div class="d-flex" v-if="this.SPYChart.prices && this.SPYChart.prices.length">
                <v-btn v-if="status != 'playing' && !is_eod" class="mr-2" color="white" elevation="0" large @click="play()"><v-icon class="mr-1">mdi-play-circle-outline</v-icon>Play</v-btn>
                <v-btn :disabled="loading" v-if="status != 'playing' && !is_eod" class="mr-2" color="white" elevation="0" large @click="forward()"><v-icon class="mr-1">mdi-fast-forward</v-icon>Step Forward</v-btn>
                <v-btn v-if="status === 'playing'" class="mr-2" color="white" elevation="0" large @click="stop()"><v-icon class="mr-1">mdi-stop-circle-outline</v-icon>Stop</v-btn>
                <v-btn :disabled="loading" v-if="is_eod" class="mr-2" color="white" elevation="0" large @click="loadNextDay()"><v-icon class="mr-1">mdi-fast-forward</v-icon>Next Day</v-btn>
                
            </div>



            <div v-if="chart_date" class="order-simulation " >
                <div class="kpi-label">Time</div>
                <div class="text-subtitle-1 ">
                    <strong>{{ getTime |  formatTime }} </strong>
                </div>
            </div>
            <v-spacer></v-spacer>
            <order-simulator :trader="trader" :trade="trade" @nextTrade="getTrade" :current_time="getTime" :game_mode="game_mode" v-if="activeTicker"></order-simulator>
        </div>
        <div v-else class="d-flex" >
            <game-mode @setMode="setGameMode"></game-mode>
        </div>
    </div>
    


    <div class="d-flex py-2" v-if="game_mode">
        <div v-if="game_mode === 2" class="left-panel" style="flex 0 1 400px"><Scanner /></div>
        
        <!-- <search-tickers v-if="game_mode === 2"></search-tickers> -->

        <div class="right-panel px-2">


            <div v-if="game_mode === 2">
                <div class="d-flex search-bar justify-space-between" style="flex: 0 1" v-if="game_mode === 2">
                        <search-tickers v-if="game_mode === 2"></search-tickers>
                        <!-- Active Trades -->  
                        <v-sheet>
                            <!-- Custom Filters --> 
                          <v-slide-group>
                                <v-slide-item v-for="(position, i) in open_positions" :key="i" v-slot="{ active, toggle }">
                                    <v-chip 
                                        outlined                                        
                                        class="mr-1 mb-1" 
                                        @click="setTicker(position.ticker)"><strong>
                                        {{position.ticker}} 
                                        <span class="pl-2" :class="current_profit(position) >= 0 ? 'success--text' : 'error--text'">{{ current_profit(position) }}</span>
                                        </strong>
                                    </v-chip>
                                </v-slide-item>
                            </v-slide-group>
                        </v-sheet>
                    </div>
            </div>

            <div class="d-flex">
                <div class="subpanel">
                    <dashboard-panel :game_mode="game_mode" id="ticker" :ticker="ticker" :active_index="active_index" :scan_data="scan_data" :current_time="getTime"></dashboard-panel>
                </div>
                <div class="subpanel">
                    <dashboard-panel :game_mode="game_mode" id="spy" ticker="SPY" :current_time="getTime"></dashboard-panel>
                </div>
            </div>
        </div>         

    </div>




        <!-- <v-container fluid >

            <div class="d-flex mt-2" v-if="game_mode"> -->
                <!-- <div class="left-column mr-2" style="flex: 1; align-items: stretch;" v-if="game_mode == 2">
                    <scanner v-if="game_mode === 2" :loading="loading" :game_mode="game_mode" :scan_data="scan_data"></scanner>
                </div> -->
                
                <!-- <div class="main-column d-flex flex-column mx-3" style="flex: 1 0; align-items: stretch;" v-if="game_mode === 2">
                    <div class="d-flex search-bar justify-space-between" style="flex: 0 1" v-if="game_mode === 2">
                        <search-tickers v-if="game_mode === 2"></search-tickers>
                        <!-- Active Trades -->  
                        <!-- <v-sheet > -->
                            <!-- Custom Filters --> 
                          <!--  <v-slide-group>
                                <v-slide-item v-for="(position, i) in open_positions" :key="i" v-slot="{ active, toggle }">
                                    <v-chip 
                                        outlined
                                        
                                        class="mr-1 mb-1" 
                                        @click="setTicker(position.ticker)"><strong>
                                        {{position.ticker}} 
                                        <span class="pl-2" :class="current_profit(position) >= 0 ? 'success--text' : 'error--text'">{{ current_profit(position) }}</span>
                                        </strong>
                                    </v-chip>
                                </v-slide-item>
                            </v-slide-group>
                        </v-sheet>
                    </div>
                </div> -->


                <!-- <div class="right-column d-flex flex-column mx-3" style="flex: 1 0; align-items: stretch;">
                    <div class="d-flex"> 
                        <div class="mx-1" style="flex: 1">
                            <dashboard-panel :game_mode="game_mode" id="ticker" :ticker="ticker" :active_index="active_index" :scan_data="scan_data"></dashboard-panel>
                        </div>

                        <div class="mx-1" style="flex: 1; mx-1">
                            <dashboard-panel :game_mode="game_mode" id="spy" ticker="SPY" :active_index="active_index"></dashboard-panel>
                        </div>


                    </div>
                </div> -->

                <!-- <div class="main-column d-flex flex-column " style="flex: 0 1 640px; align-items: stretch;">
                    
                </div> -->
<!-- 
            </div>

        </v-container>  -->
    </div>
   
</template>

<script>
  import Chart from "../dashboard/Chart"
  import { EventBus } from '../../modules/EventBus.js';

    import Scanner from "../scanner/List"
    import OrderSimulator from "./OrderSimulator";
    import SearchTickers from "../base/SearchTickers";
    import Lines from "../dashboard/Lines"
    
    import RRS from "../dashboard/RRS"
    import RVol from "../dashboard/RVol"
    import StockSectorSpy from "../dashboard/StockSectorSpy"
    import DashboardPanel from '../dashboard/DashboardPanel.vue';
    
    import { mapGetters, mapMutations } from 'vuex';
    import GameMode from './GameMode.vue';
    const { DateTime, Duration, Interval } = require("luxon");

  export default {
    name: 'TickerIndex',
    data: () => ({
        loading: false,
        game_mode: '', 
        trader: {},
        backtest: {},
        play_speed: 'slow',
        excluded_ids: [],
        lines: [],
        spy_lines: [], 
        tickers:{
           
        },
        scan_data:[],
        status: '',
        active_index: 0,
        
        spyTimeframe: 'day', 
        selectedTimeframe:'day',
        day_lines: [],
        minute_lines: ['emas'],
        start_date: 1,
        increment_periods: {
            year: 52 * 7 * 24 * 60 * 60 * 1000,
            week: 7 * 24 * 60 * 60 * 1000,
            day: 24 * 60 * 60 * 1000,
            hour: 60 * 60 * 1000,
            minute: 60 * 1000,
        },
        interval: null,
        ticker: null,
        trade:{},
        position: {
            type: null,
            open_price: '',
            close_price: '',
            status: null,
            profit: 0,
            enter_time: null,
            close_time: null,
            duration: null
        },
        results:{
            positions: [],
            wins: 0,
            losses: 0,
            amount_won: 0,
            amount_lost: 0,
            total_profit: 0
        },
        control_center: null,
        sticky: null
    }),

    components:{
        Chart, 
        DashboardPanel,
        Lines,
        RRS,
        RVol,
        StockSectorSpy,
        Scanner,
        OrderSimulator,
        GameMode,
        SearchTickers
    },

    computed: {
        ...mapGetters({
            // isLoaded: 'dashboardStore/isLoaded',
            // getIntradayChart: 'dashboardStore/getIntradayChart',
            activeTicker: 'dashboardStore/getActiveTicker',
            chart_date: 'dashboardStore/getChartDate',
            open_positions: 'positionsStore/getOpenPositions',
            getChart: 'dashboardStore/getChart',
        }),
        is_eod(){
            return this.getChart('SPY', 'minute', this.getTime?.ts)?.prices.length == this.getChart('SPY', 'minute').prices.length
        }, 
        SPYChart(){
            return this.getChart('SPY', 'minute', this.getTime?.ts);                  
        }, 
       
        // minuteChart(){
        //     return this.getChart(this.activeTicker, 'minute', this.active_index);
        // },
        // dailyChart(){
        //     return this.getChart(this.activeTicker, 'day', this.active_index);
        // },

         consolidation(ticker, timeframe){ 
            if(this.intradayTrendsChart(ticker, timeframe).consolidation){
                return ' | ' + this.intradayTrendsChart(ticker, timeframe).formation + ' ' + this.intradayTrendsChart(ticker, timeframe).consolidationPattern ;
            }
            return '';
        } , 
        getTime(){
            let date = DateTime.fromISO(this.chart_date, { zone: 'America/New_York' });
            let start_of_day = date.startOf('day');
            let market_open = start_of_day.plus({hours: 9, minutes: 30}); 
            let interval = 60 * 5;
            let current_time = market_open.plus({seconds: (this.active_index) * interval}); 
            return current_time;

        }
    },
    async mounted(){ 
               


        // When the user scrolls the page, execute myFunction
        window.onscroll =  () => { console.log("Scrolling"); this.setSticky() };

        // Get the header
        this.control_center =  this.$refs.replay_control_center;

        // Get the offset position of the navbar
        this.sticky = this.control_center.offsetTop;


        
    },

    filters:{
        round(num){
            return Math.round(num * 1e2) / 1e2;
        },
        capitalize(string){
            return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
        },
        formatDate(chart_date){
            let date = DateTime.fromISO(chart_date);
            return date.toLocaleString();
        }, 
        formatTime(time){
             return time.setZone('system').toLocaleString(DateTime.TIME_SIMPLE);
        }
        
    }, 
    destroyed(){
    
    }, 
    methods: {
       setSticky(){
           
            if (window.pageYOffset > this.sticky) {
                this.control_center.classList.add("sticky");
            } else {
                this.control_center.classList.remove("sticky");
            }

       }, 
        current_profit(position){
            
            let ticker =  this.getChart(position.ticker, 'minute', this.getTime?.ts); 
            if(!ticker?.prices?.length) return 0;
            let current_price = ticker.prices.slice(-1)[0]

            if(position.type == 'long') return this.$options.filters.round(current_price.close - position.open_price);
            if(position.type == 'short') return this.$options.filters.round(position.open_price - current_price.close);
        },


        async setGameMode(data){
         
            this.game_mode = data.game_mode; 
            this.trader = data.trader
            this.backtest = data.backtest
            if(this.game_mode === 1){
                //load random trade from a trader,
                this.getTrade();
            } else if(this.game_mode === 2) {
                this.getScanner();
                await this.getData('SPY');
            } else if(this.game_mode === 3) {
               this.getTrade();
            }
        }, 

        // formatTime(){
        //     let date = DateTime.fromISO(this.chart_date, { zone: 'America/New_York' });
        //     let start_of_day = date.startOf('day');
        //     let market_open = start_of_day.plus({hours: 9, minutes: 30}); 
        //     let interval = 60 * 5;
        //     let current_time = market_open.plus({seconds: (this.active_index) * interval}); 
            
        //     return current_time.setZone('system').toLocaleString(DateTime.TIME_SIMPLE);
        // },

        duration(time){
            const dur = Duration.fromMillis(time);

            if((time / this.increment_periods.year) > 1 ) {
                return dur.shiftTo('years', 'weeks', 'days', 'hours').toHuman({ listStyle: "long" })
            } else if( (time / this.increment_periods.week) > 1 ) {
                return dur.shiftTo('weeks', 'days', 'hours',).toHuman({ listStyle: "long" })
            } else if ( (time / this.increment_periods.day) > 1 ) {
                return dur.shiftTo('days', 'hours', 'minutes').toHuman({ listStyle: "long" });
            } else if ( (time / this.increment_periods.hour) > 1 ) {
                return dur.shiftTo('hours', 'minutes').toHuman({ listStyle: "long" });
            } else {
                return dur.shiftTo('minutes').toHuman({ listStyle: "long" });

            }    
        }, 

        async loadNextDay(){
            this.active_index = 0;
            
            // let result = await this.api.get(`/backtest/next-date/${this.chart_date}`);
            // if(!result) return; // TODO show error message
            //let date = DateTime.fromISO(result.date); 
            

            let date = DateTime.fromISO(this.chart_date); 
            
            switch(date.toFormat('c')){
                case '5':
                    date = date.plus({days: 3});
                    break;
                case '6':
                    date = date.plus({days: 2}); 
                    break;
                default: 
                    date = date.plus({days: 1}); 

            }


            
            await this.$store.dispatch('dashboardStore/setDate', {
                date: date.toFormat('yyyy-MM-dd')
            }); 

            this.active_index = 0;
    
            let p1 = this.getData('SPY');
            let p2 = this.getData(this.activeTicker); // get all tickers
            let r = await Promise.all([p1,p2]);


            this.getScanner();
            
            this.isLoaded = true;
        }, 
        

        async loadNextTrade(){
            this.trade = {};
            setActiveTicker(null)
            
            this.ticker = null;
            this.position = {
                type: null,
                open_price: '',
                close_price: '',
                status: null,
                profit: 0,
                enter_time: null,
                close_time: null,
                duration: null,
                symbol: null
            }

            await this.getTrade();
        }, 

        async getScanner(date){
            
            // let data = await this.api.get(`/backtest/scan/${this.chart_date}?period=${this.active_index}`);
            let data = await this.api.get(`/users/my-scans?game=true`);
            this.scan_data = data;
        }, 
        async getTrade(){


            let ids = '';
            let exclude_string = this.excluded_ids.map(e => `&exclude[]=${e}`);

            let url = `/backtest/random-trade?`;

            if(this.trader.id){
                url += `trader_id=${this.trader.id}`
            } else if (this.backtest.id){
                url += `backtest_id=${this.backtest.id}`
            }


            let data = await this.api.get(`${url}${exclude_string}`);
           
            this.trade = data.trade;
            let ticker = this.activeTicker;
            console.log("this.trade", this.trade)
            this.setActiveTicker(this.trade.symbol)
            this.excluded_ids.push(this.trade.id);

            await this.$store.commit('dashboardStore/removeTickerInfo', {
               ticker
            }); 

            await this.loadTrade();

            
        }, 
       
        setActiveTicker(ticker){
            this.$store.commit('dashboardStore/setActiveTicker', {
                    ticker: ticker,
                });
        },
        setTicker(ticker){
            this.$store.dispatch('dashboardStore/setTicker', {
                ticker: ticker,
                set_active: true
            });
        }, 
        async loadTrade(){


            this.isLoaded = false;
            let date = {};

            if(this.trader.id){
               date = DateTime.fromMillis(this.trade.enter_time, { zone: 'America/New_York' } );
            } else if (this.backtest.id){
               date = DateTime.fromSQL(this.trade.enter_time, { zone: 'America/New_York' } );
            }

        
            let market_open = date.set({ hour: 9, minute: 30});
            const diffMins = (date.ts - market_open.ts) / 1000 / 60
            const periods = Math.floor(diffMins / 5);
            this.active_index = periods;
                
            await this.$store.dispatch('dashboardStore/setDate', {
                date: date.toFormat('yyyy-MM-dd')
            }); 

            let p1 = this.getData('SPY');
            let p2 = this.getData(this.activeTicker);
            let r = await Promise.all([p1,p2]);

            this.isLoaded = true;

            this.getScanner();

        },
        async loadBacktest(){


            // this.isLoaded = false;
            // let date = DateTime.fromMillis(this.trade.enter_time, { zone: 'America/New_York' } );


            // let market_open = date.set({ hour: 9, minute: 30});
            // const diffMins = (date.ts - market_open.ts) / 1000 / 60
            // const periods = Math.floor(diffMins / 5);
            // this.active_index = periods;
                
            // await this.$store.dispatch('dashboardStore/setDate', {
            //     date: date.toFormat('yyyy-MM-dd')
            // }); 

            // let p1 = this.getData('SPY');
            // let p2 = this.getData(this.activeTicker);
            // let r = await Promise.all([p1,p2]);

            // this.isLoaded = true;

            // this.getScanner();

        },
        async getData(ticker){
            this.isLoaded = false;
            this.$store.dispatch('dashboardStore/setTicker',  {
                ticker: ticker
            });
        },
 
        stop(){
            clearInterval(this.interval);
            this.interval = null;
            this.status = 'stopped';
        },
        play(){
            if(!this.interval && !this.is_eod){
                this.setTimer();
                this.status = 'playing';
            }
        }, 
        reverse(){
            this.active_index--;
            
        }, 
        async forward(){
           
            this.loading = true;
            
            if(this.is_eod ) {
                this.stop();
                // return;
            }
            this.active_index++;
            if(this.status != 'playing' && this.game_mode == 2){
                await this.getScanner();
            }
            this.loading = false;
        }, 
        setTimer(){
            let count = 0;
            this.interval = setInterval(() => {
                count++;
                if(this.play_speed == 'fast' || (count % 5 === 0) ){
                    this.forward(); 
                }
            }, 200)
        }
    },
    watch: {
      activeTicker(val){
          this.ticker = val
      },
      search (val) {
            // Items have already been loaded
            // if (this.items.length > 0) return
            // Items have already been requested
            this.underlying = val;
            // if (this.isLoading) return
            // this.isLoading = true
            this.getTickers();
            
        }
    }
  }
</script>
<style>
    .replay-control-center{
        touch-action: manipulation;
        z-index: 10;
        position: fixed;
        top: 64px;
        
        left: 0;
        right: 0;
        background-color: #eef2f3;
        border-radius: 4px;
        padding: 10px;
    }
    .play-speed {
        height: 44px;
    }


    .game {
        padding-top: 55px;
    }



    .left-panel,
    .subpanel,
    .right-panel{
        flex: 1 0;
        overflow: hidden; 
    }

    .right-panel{
        flex: 2 0;
    }

</style>