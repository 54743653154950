<template>
    <div style="width: 175px">
        <v-autocomplete
            solo
            flat
            background-color="#eef2f3"
            hide-details
            v-model="model"
            :items="entries"
            :search-input.sync="search"
            item-text="ticker"
            item-value="ticker"
            placeholder="Search Tickers"
            return-object
            @keyup.enter="onEnter"
        ></v-autocomplete>

    </div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';
    export default {
        name: 'SearchTickers',
        data: () => ({
            entries: [],
            search: null,
            items: [],
            model: '',
            isLoading: false,
        }),
        computed: {
            ...mapGetters({
                activeTicker: 'dashboardStore/getActiveTicker'
            }), 
        },
        methods: {
            onEnter(e){
                if(this.search){
                    this.model = {
                        ticker: this.search
                    };
                }
            }, 
            async getTickers(){  
                let results = await this.api.get(`/tickers/search`, {
                    search: this.search
                });
                this.entries = results.tickers || []
                this.isLoading = false
            }, 
        },
        watch: {
            activeTicker(val){
                this.ticker = val
                
            },
            async model(val){
                if(!val) return;
              
                await this.$store.dispatch('dashboardStore/setTicker', {
                    ticker: this.model.ticker,
                    set_active: true
                });
                // let selectedIndex = this.tickerList.findIndex(t =>  t === this.model.ticker);
                this.model = null; 
            }, 
            search (val) {                
                // if (this.isLoading) return;
                // this.isLoading = true;
                this.getTickers();
        
            }
        }
    }


</script>