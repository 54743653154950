<template>

     <div class="d-flex" style="border: 1px solid #e2e2e2">
         <div style="flex: 0 1">
            <v-navigation-drawer permanent>
        
            <v-list
                dense
                class="ma-0 pa-0"
            >

            <v-list-item class="list-header">
                    <v-list-item-content>
                        <v-list-item-title class="overline"><Strong>Scans</Strong></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action >
                            <v-btn x-small elevation="0" class="pa-0" color="primary" @click="addFilter()"><v-icon dark small>mdi-plus</v-icon></v-btn>
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item link @click="setSelectedScan('all')">
                    <v-list-item-content>
                        <v-list-item-title>All Tickers</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action class="pt-3 pb-2">
                       
                    </v-list-item-action>
                </v-list-item>


                <v-divider></v-divider>

                <v-list-item link @click="setSelectedScan('sectors')">
                    <v-list-item-content>
                        <v-list-item-title>Sectors</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-chip class="ma-0" small>{{ sectors.Tickers && sectors.Tickers.length || 0 }}</v-chip> 
                    </v-list-item-action>
                </v-list-item>

                <v-divider></v-divider>
                <v-list-item link @click="setSelectedScan('watchlist')">
                    <v-list-item-content>
                        <v-list-item-title>Watchlist</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                         <v-chip class="ma-0" small>{{ watchlist.Tickers && watchlist.Tickers.length || 0 }}</v-chip> 
                    </v-list-item-action>
                </v-list-item>


                <v-divider></v-divider>
                  <v-list-item class="my-0 py-0 list-sub-header">
                    <v-list-item-avatar class="ma-0">
                        <v-icon  dark color="#2ecc71" left>mdi-trending-up</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content class="my-0 py-0">
                        <v-list-item-title  class="sub-header-title overline my-0 py-0">Long</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                  <v-divider></v-divider>

                <v-list-item
                    v-for="scan in preconfiguredLongScans"
                    :key="scan.name"
                     :class="{selected: scan.id === selectedScan.id}"
                    link
                    @click="setSelectedScan(scan)"
                >
                    
                  
                    <v-list-item-content>
                        <v-list-item-title>{{ scan.name }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-chip class="ma-0" small>{{ scan.Tickers.length || 0 }}</v-chip>
                    </v-list-item-action>
                </v-list-item>
                
                <v-divider></v-divider>

                <v-list-item
                    v-for="scan in longScans"
                    :key="scan.id"
                    :class="{selected: scan.id === selectedScan.id}"
                    link
                    
                    @click="setSelectedScan(scan)"
                >
                    <v-list-item-content>
                        <v-list-item-title>{{ scan.name }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-chip class="ma-0" small>{{ scan.Tickers.length }}</v-chip>
                    </v-list-item-action>
                </v-list-item>



                
                <v-divider></v-divider>
            
                <v-list-item class="my-0 py-0 list-sub-header">
                    <v-list-item-avatar class="ma-0">
                        <v-icon  dark color="#e74c3c" left>mdi-trending-down</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content class="my-0 py-0">
                        <v-list-item-title  class="sub-header-title overline my-0 py-0">Short</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                
                <v-divider></v-divider>

                 <v-list-item
                    v-for="scan in preconfiguredShortScans"
                    :key="scan.name"
                    link
                     :class="{selected: scan.id === selectedScan.id}"
                    @click="setSelectedScan(scan)"
                >
        
                    <v-list-item-content>
                        <v-list-item-title>{{ scan.name }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-chip class="ma-0" small>{{ scan.Tickers.length || 0 }}</v-chip>
                    </v-list-item-action>
                </v-list-item>


                <v-divider></v-divider>
                <v-list-item
                    
                    v-for="scan in shortScans"
                    :key="scan.id"
                    link
                     :class="{selected: scan.id === selectedScan.id}"
                    @click="setSelectedScan(scan)"
                >
                    <v-list-item-content>
                        <v-list-item-title>{{ scan.name }}</v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-chip class="ma-0" small>{{ scan.Tickers.length || 0 }}</v-chip>
                    </v-list-item-action>
                </v-list-item>
            </v-list>


            </v-navigation-drawer>
        </div>
        <div style="flex: 1">
            <v-container v-if="selectedScan.name" fluid>
                <v-row class="py-0 ticker-row title">
                    <v-col background-color="primary" cols="5" class="subtitle-2 pt-3 pb-4">{{selectedScan.name}}</v-col> 
                    <v-col cols="4" class="text-right sector-selector">
                        
                        <v-select
                            class="sector-selector"
                            v-if="selectedScan.name !== 'Sectors'"
                            dense
                            hide-details
                            background-color="white"
                            outlined
                            clearable
                            :items="sectorList"
                            item-text="etf"
                            item-value="id"
                            v-model="sector_filter"
                        >
                         <!-- <template v-slot:item="{ props }">
                             {{props}}
                         </template> -->
                        
                        </v-select>
                    </v-col>
                    <v-col cols="3" class="text-right">
                        <v-icon v-if="selectedScan.type === 'custom'" small dark class="ml-2 scan-config ma-0 pa-0" @click.stop="editFilter(selectedScan)">mdi-cog</v-icon>
                        <v-icon small dark class="ml-2 scan-config ma-0 pa-0" @click.stop="editColumns">mdi-view-column</v-icon>
                    </v-col>
                </v-row>
               
                <v-row class="py-0 ticker-row header">
                    <v-col class="pa-1 subtitle-2">SYM</v-col>
                    
                    <v-col @click="sortBy(col)"  v-for="col in user_columns" :key="col.id"  class="pa-1 subtitle-2 text-right cursor" >
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <span v-bind="attrs" v-on="on">
                                    <span>
                                    
                                    {{ getColumnHeader(col).name }}
                                    </span>
                                </span>

                            </template>
                            <span>{{ getColumnHeader(col).tooltip }}</span>
                            </v-tooltip>
                    </v-col>
                </v-row>
                
                <v-row v-for="ticker in filteredTickers"  @click="handleRowClick(ticker)" :key="ticker.id" class="py-1 ticker-row body" style="border-bottom: 1px solid #e2e2e2">
                    <v-col class="py-0 px-1">{{ticker.symbol}}</v-col>
                    <v-col class="py-0 px-1 text-right" v-for="col in user_columns" :key="col.id">{{getColumnData(col, ticker) }}</v-col>
           
                </v-row>
            </v-container>
        </div>

        
    
        <v-dialog persistent transition="slide-y-transition" max-width="600" class="filter-panel" v-model="showAddFilter">
            <configure-filter 
                v-if="showAddFilter" 
                :selected="selectedFilter" 
                :timeframes="timeframes" 
                :indicators="indicators" 
                @close="showAddFilter = false" 
                @saved="saveFilter" 
                @delete="deleteFilter">
            </configure-filter>
        </v-dialog>

            
        <v-dialog persistent transition="slide-y-transition" max-width="600" class="filter-panel" v-model="showConfigColumns">
            <configure-columns 
                v-if="showConfigColumns" 
                :selected="user_columns"  
                :timeframes="timeframes" 
                :indicators="indicators" 
                @close="showConfigColumns = false" 
                @saved="saveColumns" 
               ></configure-columns>
        </v-dialog>
    
    </div>
    
  
</template>
     
 <script>
    import { EventBus } from '../../modules/EventBus.js';
    import { mapGetters, mapMutations } from 'vuex';
       import ConfigureFilter from './ConfigureFilter.vue';
       import ConfigureColumns from './ConfigureColumns.vue';
    export default {
        name: 'App',
        data: () => ({
            sort: {
                col: '',
                dir: ''
            },
            selectedScan: {},
            showConfigColumns: false,
            preconfigurdScans: [],
            showAddFilter: false,
            selectedFilter: {},
            
            sector_filter: null, 
            sectorList:[
                
            ], 
            timeframes: [{
                label: 'Minute',
                name: "M5"
            },
            {
                label: 'Day',
                name: "D1"
            },

            
        ], 
        defaultColumns: [
            {
                indicator_id: 22,
                timeframe: 'D1' 
            },
            {
                indicator_id: 30,
                timeframe: 'M5' 
            },
            {
                indicator_id: 6,                
                timeframe: 'M5' 
            }
        ]
        }),
        async created(){ 
        
            EventBus.$on('scanner', this.update);
            EventBus.$on('updateWatchlist', this.setData);
            EventBus.$on('setFilter', this.setFilter);
        
             this.resetColumns();
            if(this.game_mode){
                if(!this.scan_data) return;
                this.raw_data = JSON.parse(JSON.stringify(this.scan_data));
                this.setData(); 

            } else {
                this.getScanResults();
                this.getWatchlist();
                this.getSectors();
                this.getSectorList();
                this.getAllTickers();
            
            }
    },

        components: {
            ConfigureFilter,
            ConfigureColumns
        },

        computed: {

            filteredTickers(){
                
                let tickers = JSON.parse(JSON.stringify(this.selectedScan.Tickers));
                if(this.sector_filter && this.selectedScan.name !== 'Sectors') {
                    tickers = tickers.filter(t => console.log("t", t, this.sector_filter) ||  t.sector_id === this.sector_filter);
                };

                if(this.sort.col){
                    tickers = tickers.sort((a, b) => (+a[this.sort.col] > +b[this.sort.col]) ? 1 : -1);
                    if(this.sort.dir == "DESC") tickers.reverse();
                }                
            
                return tickers.splice(0, 50);

            }, 
            longScans(){
                return this.scans.filter(s => s.side == 'long')
            }, 
            shortScans(){
                return this.scans.filter(s => s.side == 'short')
            }, 
            preconfiguredLongScans(){
                return this.preconfiguredScans.filter(s => s.side == 'long')
            }, 
            preconfiguredShortScans(){
                return this.preconfiguredScans.filter(s => s.side == 'short')
            }, 
            ...mapGetters({
                sectors: 'scannerStore/getSectors',
                watchlist: 'scannerStore/getWatchlist',
                allTickers: 'scannerStore/getAllTickers',
                activeTicker: 'dashboardStore/getActiveTicker',
                scans: 'scannerStore/getScans' ,
                preconfiguredScans: 'scannerStore/getPreconfiguredScans' ,
                indicators: 'scannerStore/getIndicators',
                user: 'authenticationStore/getUser' 
            })
        },
        methods:{
            async getSectorList(){
                let results = await this.api.get('/scanner/sector-list');
                
                this.sectorList = results.sectors; 
            }, 
            sortBy(col) {
                let indicator = this.indicators.find(i => i.id == col.indicator_id);
                let timeframe = this.timeframes.find(t => t.name == col.timeframe);
                let key = `${timeframe.label.toLowerCase()}_${indicator.key}`
    
                if(this.sort.col === key){
                    if(this.sort.dir == 'ASC'){
                        this.sort.dir = 'DESC'
                    } else {
                        this.sort = {
                            col: '',  
                            dir: ''
                        }
                    }
                } else {
                    this.sort = {
                        col: key, 
                        dir: "ASC"
                    }
                }

                console.log(this.sort)
            }, 


            getColumnData(col, ticker){
                let indicator = this.indicators.find(i => i.id == col.indicator_id);
                let timeframe = this.timeframes.find(t => t.name == col.timeframe);
                let key = `${timeframe.label.toLowerCase()}_${indicator.key}`;
                
                return ticker[key];

                // ticker.day_relative_strength
            },
            getColumnHeader(col){
                
                let indicator = this.indicators.find(i => i.id == col.indicator_id);
                let timeframe = this.timeframes.find(t => t.name == col.timeframe);
                
                return  {       
                    name: timeframe.name + ' ' + indicator.short_name,
                    tooltip: indicator.name
                }
            }, 
            editColumns(){
                this.showConfigColumns = true;
            }, 
            resetColumns(){
                let user_columns = this.user.column_config;

                this.user_columns = user_columns || JSON.parse(JSON.stringify(this.defaultColumns)) ;
            }, 
            async saveColumns(cols){
                this.api.post('/users/column-config', {
                    column_config: JSON.stringify(cols)
                });
                this.$store.commit( 'authenticationStore/setColumnConfig', cols);
                this.user_columns = JSON.parse(JSON.stringify(cols)) ;
                 this.showConfigColumns = false;
            }, 
            
            setSelectedScan(scan){
                switch(scan){
                    case 'watchlist':
                        this.selectedScan = this.watchlist
                        break;
                    case 'sectors':
                        this.selectedScan = this.sectors
                        break;
                    case 'all':
                         this.selectedScan = this.allTickers;
                        break;
                    default: 
                        this.selectedScan = scan;

                }
            },
             addFilter(){
                this.showAddFilter = !this.showAddFilter;
                this.selectedFilter = {};
            }, 
            editFilter(filter){
                this.selectedFilter = filter;
                this.showAddFilter = !this.showAddFilter;
            }, 
            async saveFilter(scan){
                this.selectedFilter = {};
                this.showAddFilter = false; 
                this.getScanResults();
            }, 
            async getScanResults(){
               this.$store.dispatch( 'scannerStore/getUserScans');
            }, 
            async getWatchlist(){
               this.$store.dispatch( 'scannerStore/getUserWatchlist');
            }, 
             async getSectors(){
               this.$store.dispatch( 'scannerStore/getSectors');
            }, 
            async getAllTickers(){
               this.$store.dispatch( 'scannerStore/getAllTickers');
            }, 
            deleteFilter(filter_id){
                this.selectedFilter = {};
                this.showAddFilter = false; 
                this.getScanResults();
            }, 
            async handleRowClick(data){
                await this.$store.dispatch('dashboardStore/setTicker',  {
                    ticker: data.symbol,
                    set_active: true
                });
            }, 
            
        },
        watch: { 
            watchlist(){
                this.setSelectedScan(this.watchlist);
            }
        }
        
    }
</script>
<style>
    .sector-selector .v-text-field.v-text-field--solo .v-input__control{
        min-height: 10px;
    }
</style>
<style scoped>
    .v-list-item,
    .ticker-row{
        /* border-bottom: 1px solid #e2e2e2; */
    }
    .v-list-item {
         border-left: 3px solid #FFFFFF;
    }
    .v-list-item.selected{
        background-color: #eef2f3;
        border-left: 3px solid #2f69ff;
    }
    .list-sub-header{
        /* background-color: #eef2f3; */
    }
    .list-header{
       /* border-top: 1px solid #e2e2e2; */
       border-bottom: none;
        
    }
    .list-sub-header .sub-header-title{
        color: #2f69ff;
    }
    .ticker-row.body{
        cursor: pointer;
    }
    .ticker-row.header{
        background-color: #e2e2e2;
    }

    .ticker-row.title{
        background-color: #363c4f;
        color: white
    }


     .ticker-row > div{
        padding: 5px 16px;
    }

    .ticker-row.body:hover{
        background-color: #f5f5f5;
        
    }
    .v-list--dense .v-list-item{
        min-height: 30px;
    }
    .cursor{
        cursor: pointer
    }


</style>