<template>
    <div class="d-flex flex-column justify-start align-stretch">
        
        
    <v-card> 
        <v-card-title class="text-h6">Configure Columns <v-spacer></v-spacer></v-card-title>
        
        <v-alert v-if="error" dense color="error" dark> {{error}} </v-alert>
        <v-card-text>
            <v-container >
            
                <draggable v-model="columns" @start="drag=true" @end="drag=false" draggable=".drag-item">
                    <v-row  v-for="(c, i) in columns " :key="i" class="drag-item">
                        <v-col cols="1" class="px-3" ><v-icon>mdi-drag-vertical</v-icon></v-col>
                        <v-col class="py-3"> {{getColumnHeader(c)}}  </v-col>

                        <v-col cols="1" class="pl-2 pt-1 pb-0">
                            <v-btn color="error" @click="removeItem(i)" small text class="cancel-btn px-0"><v-icon>mdi-trash-can-outline</v-icon></v-btn>
                        </v-col>

                    </v-row>
                    
                 </draggable> 
            </v-container>
            <v-container>
            
                <v-row class=" px-3"  v-show="showAddColumn">
                    <v-col cols="8" class="pl-3 pr-2 configuration-row" >
                        <v-autocomplete
                            background-color="white"
                            v-model="selected_setting.Indicator"
                            label="Choose Indicator"
                            :items="indicators"
                            item-text="name"
                            return-object
                            outlined
                            dense
                            hide-details
                        ></v-autocomplete>
                </v-col>
                    
                <v-col cols="3" class="pl-0 pr-3 configuration-row">
                    <v-select
                        background-color="white"
                        :items="timeframes"
                        v-model="selected_setting.Timeframe"
                        label="TF"
                        return-object
                        item-text="name"
                        outlined
                        dense
                        hide-details
                        ></v-select>
                </v-col>
                    <v-col cols="1" class="pl-0 pr-3 pb-0 configuration-row">
                    <v-btn @click="addColumn" class="filter-config px-0" elevation="0" color="primary" dense :disabled="!selected_setting.Timeframe || !selected_setting.Indicator" ><v-icon>mdi-chevron-right</v-icon></v-btn>
                </v-col>
                

                </v-row>

                
                <v-row no-gutters class="mt-4 px-3" v-show="!showAddColumn">
                    <v-col>
                        
                        <v-btn elevation="0" dense small color="primary" text class="mr-1 px-0" @click="add"><v-icon>mdi-plus</v-icon>Add Indicator</v-btn>
                    </v-col>
                </v-row>
            </v-container>
            
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
            
            <v-btn elevation="0" dense outlined color="error" @click="resetColumns">Reset Columns</v-btn>
            <v-spacer></v-spacer>
            <v-btn elevation="0" dense color="neutral" @click="$emit('close')">Close</v-btn>
            <v-btn elevation="0" dense color="primary" @click="saveColumns">Save</v-btn>
        </v-card-actions>

    </v-card>

    
    </div>
        
    </template>

</template>
<script>
import draggable from 'vuedraggable'
    export default {
        name: 'Scanner',
        data: () => ({
            error: null,
            columns: [],
            defaultColumns: [
                {
                    indicator_id: 22,
                    timeframe: 'D1' 
                },
                {
                    indicator_id: 30,
                    timeframe: 'M5' 
                },
                {
                    indicator_id: 6,                
                    timeframe: 'M5' 
                }
            ],
            showAddColumn: false,
            selected_setting: {
                Timeframe: '', 
                Indicator: ''
            }
        }),
        components: {draggable},
        props: ['selected', 'timeframes', 'indicators'], 
        created(){
            if(this.selected){
                this.columns = JSON.parse(JSON.stringify(this.selected));
            }
        }, 
        methods: {
            resetColumns(){
                  this.columns = JSON.parse(JSON.stringify(this.defaultColumns));
            }, 
            removeItem(index){
                this.columns.splice(index,1)
            }, 

            getColumnHeader(col){ 
                let indicator = this.indicators.find(i => i.id == col.indicator_id);
                let timeframe = this.timeframes.find(t => t.name == col.timeframe);    
                console.log("indicator", indicator)            
                return  `${timeframe.name} ${indicator.short_name}  (${indicator.name})` ;
            }, 
            add(){
                this.showAddColumn = true;
            }, 
            async saveColumns(){
                try {
                    
                    this.$emit('saved', this.columns);   
                } catch(err){
                    console.log("err", err)
                }
                
            }, 
            
            addColumn(){
                console.log("this.selected_setting.Timeframe", this.selected_setting.Timeframe)
                this.columns.push({
                    indicator_id: this.selected_setting.Indicator.id,
                    timeframe: this.selected_setting.Timeframe.name
                });
                this.showAddColumn = false;
                this.selected_setting = {
                    Timeframe: '', 
                    Indicator: ''
                }
               
            },
           
        }
    }
</script>
<style scoped>

    .configuration-row{
        background-color: #eef2f3;
    }
</style>
<style>
.cancel-btn.v-btn:not(.v-btn--round), 
.filter-config.v-btn:not(.v-btn--round) {
        padding: 0 0px;
        min-width: 25px;
}
.filter-panel.theme--light.v-sheet{
    background-color: #eef2f3;
    padding: 10px;
    margin-bottom: 15px;
}

.drag-item > div{
    border-bottom: 1px solid #e2e2e2;
}
</style>
