<template>
    <div class="d-flex">
        <div class="mr-2" style="flex: 1 0 200px">
                <div class="kpi-label mb-1">Choose A Game Mode</div>
                <v-select
                    solo
                    flat
                    background-color="#FFFFFF"
                    hide-details
                    v-model="game_mode"
                    :items="game_modes"
                    item-text="label"
                    
                    placeholder="Select One"
                ></v-select>
            </div>

            <div class="mr-2" style="flex: 1 0 200px" v-if="game_mode === 1">
                <div class="kpi-label mb-1">Choose A Trader</div>
                <v-select
                    solo
                    flat
                    background-color="#FFFFFF"
                    hide-details
                    v-model="trader"
                    :items="traders"
                    item-text="name"
                    return-object
                    placeholder="Select One"
                ></v-select>
            </div>

            <!-- <div class="mr-2" style="flex: 1 0 200px" v-if="game_mode === 1">
                <div class="kpi-label mb-1">Choose A Dataset</div>
                <v-select
                    solo
                    flat
                    background-color="#FFFFFF"
                    hide-details
                    v-model="dataset"
                    :items="datasets"
                    item-text="name"
                    return-object
                    placeholder="Select One"
                ></v-select>
            </div> -->

            <div class="mr-2" style="flex: 1 0 200px" v-if="game_mode === 2">
                <div class="kpi-label mb-1">Choose Date</div>
                    <v-menu ref="menu" v-model="date_picker_menu" :close-on-content-click="false" transition="scale-transition" offset-y left  min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field 
                                :value="date_picker_menu_date" 
                                solo
                                flat 
                                hide-details 
                                background-color="white" 
                                prepend-inner-icon="mdi-calendar" 
                                readonly 
                                v-bind="attrs" 
                                v-on="on">
                            </v-text-field>
                        </template>
                        <v-date-picker :max="max_date" v-model="date"  scrollable >
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="date_picker_menu = false">Cancel</v-btn>
                            <v-btn text color="primary" @click="saveDate">OK</v-btn>
                        </v-date-picker>
                </v-menu>
            </div>


            <div class="mr-2" style="flex: 1 0 200px" v-if="game_mode === 3">
                    <div class="kpi-label mb-1">Choose A Backtest</div>
                    <v-select
                        solo
                        flat
                        background-color="#FFFFFF"
                        hide-details
                        v-model="backtest"
                        :items="backtests"
                        item-text="name"
                        return-object
                        placeholder="Select One"
                    ></v-select>
            </div>


            <div>
                <div class="kpi-label mb-1">&nbsp;</div>
                <v-btn  class="px-8" color="primary" elevation="0" large  @click="setGameMode">Start Game!</v-btn>
            </div>
    </div>
</template>



<script>

    import { mapGetters, mapMutations } from 'vuex';
    const { DateTime } = require("luxon");
    export default {
        name: 'GameMode',
        data: () => ({
            game_mode: '', 
            game_modes: [
                {
                    value: 1,
                    label: 'Trade With The Pros'
                },
                {
                    value: 2,
                    label: 'Replay A Day'
                },
                {
                    value: 3,
                    label: 'Backtest Results'
                },
            ],
            date_picker_menu: '',
            date_picker_menu_date: '',
            date: '', 
            max_date: '',
            traders:[],
            backtests:[],
            trader: {}, 
            backtest: {}, 

        }),
        async created(){
            var d = new Date();    
            d = new Date(d.getTime() - d.getTimezoneOffset() * 60000)
            this.date = d.toISOString().split('T')[0]
            this.max_date = new Date().toISOString().split('T')[0];

            await this.getTraders();
            await this.getBacktests();
        },
        computed: {
            ...mapGetters({
                activeTicker: 'dashboardStore/getActiveTicker',
                 chart_date: 'dashboardStore/getChartDate'
            }), 
        }, 
        methods: {
            async setGameMode(){
                if(!this.game_mode) return;
                if(this.game_mode ===  1 && !this.trader.id) return;
                if(this.game_mode ===  2 && !this.date_picker_menu_date) return;
                if(this.game_mode ===  3 && !this.backtest.id) return;
                await this.$store.dispatch('dashboardStore/setDate', {
                    date: this.date
                });
                this.$emit('setMode', {
                    game_mode: this.game_mode, 
                    trader: this.trader,
                    backtest: this.backtest,

                });
            }, 

            async saveDate(){
                let formatted_date = DateTime.fromISO(this.date);
                
                this.date_picker_menu_date = formatted_date.toLocaleString();
                this.date_picker_menu = false;
            },

            async getTraders(){
                let data = await this.api.get(`/backtest/traders`);
                this.traders = data.traders;
            }, 
            async getBacktests(){
                let data = await this.api.get(`/backtest/backtests`);
                this.backtests = data.backtests;
            },
        } 
    }

</script>
