<template>
    <div class="d-flex">
        <div  class="d-flex " v-if="this.results.closed_positions.length">
            <v-dialog transition="slide-y-transition" max-width="960">
                <template v-slot:activator="{ on, attrs }">

                    <v-btn
                        v-bind="attrs"
                        v-on="on"
                        @click="viewResults" color="primary" text elevation="0" large >Results</v-btn>

 
                   </template>
                    <template v-slot:default="dialog">
                        <v-card>
                        <v-toolbar color="primary" dark>Session Results</v-toolbar>
                        <v-card-text>
                            
                            <div class="d-flex pa-5 mt-5 justify-space-around" style="background-color: #eef2f3" > 
                                <div class="mx-4 text-center">

                                    <div class="kpi-metric">{{results.wins}}</div>
                                    <div class="kpi-label">Wins</div>
                                </div>    
                                <div class="mx-4 text-center">
                                    <div class="kpi-metric">{{results.losses}}</div>
                                    <div class="kpi-label">Losses</div>
                                </div>    
                                 <div class="mx-4 text-center">    
                                   <div class="kpi-metric">{{ (results.wins / (results.wins + results.losses ) ) | round  }}</div> 
                                    <div class="kpi-label">WR</div>
                                 </div>
                                  <div class="mx-4 text-center">  
                                    <div class="kpi-metric">{{  (Math.abs(results.amount_lost) > 0 ? (results.amount_won / Math.abs(results.amount_lost)).toFixed(2)  : '-') }}</div>
                                    <div class="kpi-label">PF</div>
                                 </div>

                                <div class="mx-4">  
                                    <div class="kpi-metric" :style="{ color: results.total_profit > 0 ? '#2ecc70': '#e74d3c'}" >${{ results.total_profit | round }}</div>
                                    <div class="kpi-label">Total Return</div>
                                </div> 
                            </div>

                            <v-container fluid class="my-2" >
                                <v-row  style="background-color: #eef2f3">        
                                    <v-col><strong>Ticker</strong></v-col>
                                    <v-col cols="3"><strong>Enter Time</strong></v-col>
                                    <v-col><strong>Type</strong></v-col>
                                    <v-col><strong>Profit</strong></v-col>
                                    <v-col cols="2"><strong>Swing Days</strong></v-col>
                                    <v-col cols="3"><strong>Hold Time</strong></v-col>
                                </v-row>
 

                                <v-row  v-for="(position, i) in results.closed_positions" :key="i" style="border-bottom: 1px solid #e2e2e2;">
                                    <v-col>{{position.ticker}}</v-col>
                                    <v-col cols="3">
                                        <span v-if="position.enter_time">{{position.enter_time | formatDateTime}}</span>
                                    </v-col>
                                    
                                    <v-col>{{position.type | capitalize}}</v-col>
                                    <v-col v-if="position.profit != 0" :style="{ color: position.profit > 0 ? '#2ecc70': '#e74d3c'}">
                                        <strong>{{position.profit.toFixed(2)}}</strong>
                                    </v-col>
                                    <v-col v-else> - </v-col>
                                    
                                     <!-- <v-col><strong>{{position.trade.profit || '-' }}</strong></v-col> -->
                                     <v-col cols="2">{{position | swingDays }}</v-col>
                                     <v-col  cols="3">{{position | holdTime }}</v-col>

                                </v-row>

                            </v-container>

                            
                        </v-card-text>
                         <v-divider></v-divider>
                        <v-card-actions class="justify-end pa-3">
                        
                            <v-btn text @click="dialog.value = false">Close</v-btn>
                        </v-card-actions>
                    </v-card>
                    </template>
                </v-dialog>



            

        </div>
        <div  class="d-flex order-simulation">
            
            <div class="mr-8">
                <div class="kpi-label">Ticker</div>
                <div class="kpi-metric">{{activeTicker}}</div> 
            </div>
            
            <div class="mr-8" v-if="open_position">
                <div class="kpi-label">Direction</div>
                <div class="kpi-metric">{{open_position.type | capitalize}}</div>
            </div>

            <div class="mr-8" v-if="open_position">
                <div class="kpi-label">Avg. Price</div>
                <div class="kpi-metric">${{open_position.open_price | round}}</div>
            </div>

            <div class="mr-8">
                <div class="kpi-label">Price</div>
                <div class="kpi-metric">${{current_price.close  | round}}</div>
            </div>

            
            <div class="mr-8" v-if="( game_mode === 2 || game_mode === 1) && open_position">
                <div class="kpi-label">Shares</div>
                <div class="kpi-metric">{{open_position.shares}}</div>
            </div>

            <div class="mr-8" v-if="open_position">
                <div class="kpi-label">Total RPS</div>
                <div class="kpi-metric" :style="{ color: current_profit > 0 ? '#2ecc70': '#e74d3c'}">${{ current_profit }}</div>
            </div>

            <template v-if="!open_position">
                <div class="mr-2" v-if="game_mode === 2 || !closed">
                    <v-btn class="px-8" color="success" elevation="0" large  @click="open('long', current_price.close, current_price.time)">Buy</v-btn>
                </div>

                <div v-if="game_mode === 2 || !closed">
                    <v-btn  class="px-8" color="error" elevation="0" large  @click="open('short', current_price.close, current_price.time)">Sell</v-btn>
                </div>

                    
                <div v-if="game_mode === 1 || game_mode === 3">
                    <v-btn  text class="px-8 text--lighten-4 capitalize" elevation="0" large  @click="loadNextTrade">
                        <span v-if="closed">Next Trade</span>
                        <span v-else>Skip</span>
                    </v-btn>
                </div>

            </template>

            <template v-if="open_position">
                <div>
                    <v-btn  class="px-8" color="primary" elevation="0" large  @click="close(current_price.close, current_price.time)">Close</v-btn>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
  const { DateTime, Duration, Interval } = require("luxon");
import { mapGetters, mapMutations } from 'vuex';
export default {
    name: 'OrderSimluator',
    data: () => ({

        closed: false,
        position: {
            type: null,
            open_price: '',
            close_price: '',
            status: null,
            profit: 0,
            shares: 0,
            enter_time: null,
            close_time: null,
            duration: null
        },

        show_results: false,

    }),
    props: ['game_mode', 'current_time', 'trade', 'trader'], 
    computed:{
        ...mapGetters({
            activeTicker: 'dashboardStore/getActiveTicker',
            getChart: 'dashboardStore/getChart',
            chart_date: 'dashboardStore/getChartDate', 
            open_positions: 'positionsStore/getOpenPositions', 
            results: 'positionsStore/getResults'
        }), 
         current_price(){
            let ticker =  this.getChart(this.activeTicker, 'minute', this.current_time.ts);  
            if(!ticker?.prices?.length) return {};
            return ticker.prices.slice(-1)[0]
        },
        total_profits(){
            return results.closed_positions.reduce( (a,b) => a + b.profit);
        },
        open_position(){
            return this.open_positions.find(p => p.ticker === this.activeTicker); 
        }, 
        current_profit(){
            if(this.open_position.type == 'long') return this.$options.filters.round(this.current_price.close - this.open_position.open_price);
            if(this.open_position.type == 'short') return this.$options.filters.round(this.open_position.open_price - this.current_price.close);
        },
        atr(){
            let chart = this.getChart(this.activeTicker, 'day');
            console.log("chart", chart)
            if(!chart || !chart.prices.length) return;
            return chart.prices[0].atr;
        }

    },
     filters:{
        round(num){
            return Math.round(num * 1e2) / 1e2;
        },
        capitalize(string){
            return string ? string.charAt(0).toUpperCase() + string.slice(1) : "";
        },
        formatDateTime(date){
            
            let d = DateTime.fromMillis(date);
            return d.toLocaleString(DateTime.DATETIME_SHORT); 
        }, 
        holdTime(trade){
            if(trade.type == 'skipped') return '-';
            const dur = Duration.fromMillis(trade.close_time - trade.enter_time);
            return dur.shiftTo('days', 'hours', 'minutes').toHuman({ listStyle: "long" });
        },
        swingDays(trade){
            if(trade.type == 'skipped') return '-';
            let start = DateTime.fromMillis(trade.enter_time).startOf('day');
            let end = DateTime.fromMillis(trade.close_time).startOf('day');            
            const diff = Interval.fromDateTimes(start, end);
            const diffDays = diff.length('days');
            return diffDays;
        }
    }, 
    methods:{

        viewResults(){
            this.show_results = true;
        }, 
        loadNextTrade(){
            if(!this.closed){
                let position = {
                    ticker: this.activeTicker,
                    type: 'skipped',
                    open_price: null,
                    close_price: null, 
                    status: 'skipped',
                    shares: 0,
                    profit: 0,
                    enter_time: null,
                    close_time: null,
                    duration: null,
                    trade: this.trade || null,
                    orders: [], 
                }

                this.$store.commit('positionsStore/skipPosition', {
                    position
                })

            }

           this.$emit('nextTrade');
            this.closed = false;
        },
        
        open(type, price, time){
            
            let position = {
                ticker: this.activeTicker,
                type: type,
                open_price: price,
                close_price: '',
                status: 'open',
                profit: 0,
                shares: Math.round(400 / this.atr),
                enter_time: time,
                close_time: null,
                duration: null,
                orders: [], 
                trade: this.trade || null
            }
            console.log(position)
            this.$store.commit('positionsStore/addOpenPosition', position)
            
        }, 
    
        close(price, time){
           
            this.closed = true;
            this.$store.commit('positionsStore/closePosition', {
                ticker: this.activeTicker, 
                price,
                time
            });
            
        }, 

    }
}

</script>
